* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

/* .home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
} */

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
@media screen and (max-width: 383px) {
  :root{
    width: 110%;
  }
   .thank-you{
    padding: 3rem 0 50rem 0

  }  .mainDiv {
    height: 110vh;
  }
} 
@media screen and (max-width: 320px) {
  :root{
    width: 140%;
  }
  .mainDiv{
    height: 140vh;
  }

}
@media screen and (min-width: 383px){
  .mainDiv {
    height: 100vh;
  }
}
.thank-you {
  /* background-image: url('/images/kade.jpg');
  background-repeat:repeat;
  background-size:auto;
   */
  background: linear-gradient(90deg, rgba(249,203,150,255) 0%, rgba(140,140,140,255));
  padding: 3rem 0 120rem 0
} 
