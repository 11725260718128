/* @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');

:root{
  --primary: #efb6b2;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
} */

/* base styles & title */
/* body{
  font-family: "Noto Serif";
  color: var(--secondary);
}
.App{
  max-width: 960px;
  margin: 0 auto;
}
.title h1{
  color: var(--primary);
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: normal;
}
.title h2, .title p{
  text-align: center;
}
.title h2{
  margin-top: 60px;
  font-size: 2.6rem;
} */

/* upload form styles */
/* form{
  margin: 30px auto 10px;
  text-align: center;
}
label input{
  height: 0;
  width: 0;
  opacity: 0;
}
label{
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  margin: 10px auto;
  line-height: 30px;
  color: var(--primary);
  font-weight: bold;
  font-size: 24px;
}
label:hover{
  background: var(--primary);
  color: white;
}
.output{
  height: 60px;
  font-size: 0.8rem;
}
.error{
  color: var(--error);
} */

/* progress bar styles */
/* .progress-bar{
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
} */

/* image grid styles */
/* .img-grid{
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}
.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0; */
  /* padding controls height, will always be perfectly square regardless of width */
  /* position: relative;
  opacity: 0.8;
}
.img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
} */

/* modal styles */
.modal{
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  background: linear-gradient(180deg, rgba(140,140,140,255) 0%,rgba(249,203,150,255) );

  border-radius: 10px;
  text-align: center;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  
}
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.backdrop img{
  display: block;
  max-width: 80%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}
.closeBttn {
  padding: 1em;
  position: relative;
  left: 140px;
  top: 15px;
  /* color: white; */
}

.modalpics{
  height: 250px;
  width: 350px;
}

@media screen and (max-width: 540px) {
  .backdrop img {
    max-width: 90%;
    max-height: 90%;
  }

} 
/* small an narrow screen */
@media screen and (max-width: 280px) {
  .modalpics{
    height: 400px;
    width: 350px;
  }
  .closeBttn {
    padding: 1em;
    position: relative;
    left: 150px;
    top: 20px;
    font-size: large;
    
    /* background-color: white; */

  }
  .price {
    font-size: 27px;
  }
  .backBttn {
    position: relative;
    left: -10px;
    top: -100px;
    width: 130px;
  }
  .addBttn {
    position: relative;
    left: 10px;
    top: -100px;
    width: 130px;
  }
  .stockxBttn {
    top: -48px;
    
    
  }

} 

@media screen and (max-width: 320px) {
  .price {
    font-size: 21px;
  }
  .backBttn {
    position: relative;
    left: -10px;
    top: -145px;
    width: 130px;
  }
  .addBttn {
    position: relative;
    left: 10px;
    top: -145px;
    width: 130px;
  }
  .modalTitle {
    font-size: 27px;
  }
  .stockxBttn {
    top: -48px;
    font-size: 18px;
    
  }
  .modalpics{
    height: 400px;
    width: 400px;
  }
  .closeBttn {
    padding: 1em;
    position: relative;
    left: 170px;
    top: 20px;
    font-size: large;
    
    /* background-color: white; */

  }

}

@media screen and (min-width: 360px) {
  .price {
    font-size: 17px;
  }
  .backBttn {
    position: relative;
    left: -10px;
    top: -155px;
    width: 130px;
  }
  .addBttn {
    position: relative;
    left: 10px;
    top: -155px;
    width: 130px;
  }
  .modalTitle {
    font-size: 20px;
  }
  .description {
    font-size: 15px;
  }
  .stockxBttn {
    top: -65px;
    font-size: 18px;
    
    
  }
  .modalpics{
    height: 360px;
    width: 350px;
  }
  .closeBttn {
    padding: 1em;
    position: relative;
    left: 170px;
    top: 20px;
    font-size: large;
    
    /* background-color: white; */

  }
}
@media screen and (min-width: 411px) {
  .price {
    font-size: 17px;
  }
  .backBttn {
    position: relative;
    left: -10px;
    top: -155px;
    width: 130px;
  }
  .addBttn {
    position: relative;
    left: 10px;
    top: -155px;
    width: 130px;
  }
  .modalTitle {
    font-size: 20px;
  }
  .description {
    font-size: 15px;
  }
  .stockxBttn {
    top: -65px;
    font-size: 18px;
    
    
  }
  .modalpics{
    height: 360px;
    width: 390px;
  }
}

/* @media screen and (min-width: 414px) {
  .price {
    font-size: 17px;
  }
  .backBttn {
    position: relative;
    left: -10px;
    top: -155px;
    width: 130px;
  }
  .addBttn {
    position: relative;
    left: 10px;
    top: -155px;
    width: 130px;
  }
  .modalTitle {
    font-size: 20px;
  }
  .description {
    font-size: 15px;
  }
  .stockxBttn {
    top: -65px;
    font-size: 18px;
    
    
  }
  .modalpics{
    height: 240px;
    width: 350px;
  }
} */
@media screen and (min-width: 540px) {
  .price {
    font-size: 17px;
  }
  .backBttn {
    position: relative;
    left: -10px;
    top: -155px;
    width: 130px;
  }
  .addBttn {
    position: relative;
    left: 10px;
    top: -155px;
    width: 130px;
  }
  .modalTitle {
    font-size: 20px;
  }
  .description {
    font-size: 15px;
  }
  .stockxBttn {
    top: -60px;
    font-size: 18px;
    
    
  }
  .modalpics{
    height: 400px;
    width: 400px;
  }
  .closeBttn {
    padding: 1em;
    position: relative;
    left: 215px;
    top: 20px;
    font-size: large;
    
    
  }
}

@media screen and (min-width: 768px) {
  .price {
    font-size: 22px;
  }
  .backBttn {
    position: relative;
    left: -10px;
    top: -155px;
    width: 130px;
  }
  .addBttn {
    position: relative;
    left: 10px;
    top: -155px;
    width: 130px;
  }
  .modalTitle {
    font-size: 25px;
  }
  .description {
    font-size: 18px;
  }
  .stockxBttn {
    top: -60px;
    font-size: 18px;
    
    
  }
  .modalpics{
    height: 420px;
    width: 600px;
  }
  .closeBttn {
    padding: 1em;
    position: relative;
    left: 275px;
    top: 20px;
    font-size: large;
    
    
  }
}
/* @media screen and (min-width: 893px) {

  .leftBttn {
    position: relative;
    left: -100px;
    font-size: 2rem;
  }
  .rightBttn {
    position: relative;
    right: -100px;
    font-size: 2rem;
  }
} */
@media screen and (min-width: 1024px) {
  .price {
    font-size: 28px;
  }
  .backBttn {
    position: relative;
    left: -10px;
    top: -135px;
    width: 130px;
  }
  .addBttn {
    position: relative;
    left: 10px;
    top: -135px;
    width: 130px;
  }
  .modalTitle {
    font-size: 32px;
  }
  .description {
    font-size: 22px;
  }
  .stockxBttn {
    top: -40px;
    font-size: 18px;
    
    
  }
  .modalpics{
    height: 620px;
    width: 700px;
  }
  .closeBttn {
    padding: 1em;
    position: relative;
    left: 350px;
    top: 20px;
    font-size: 2rem;
    color: white;
    
    
  }
}

@media screen and (min-width: 1190px) {
  .price {
    font-size: 24px;
  }
  .backBttn {
    position: relative;
    left: -10px;
    top: -135px;
    width: 130px;
  }
  .addBttn {
    position: relative;
    left: 10px;
    top: -135px;
    width: 130px;
  }
  .modalTitle {
    font-size: 28px;
  }
  .description {
    font-size: 18px;
  }
  .stockxBttn {
    top: -40px;
    font-size: 18px;
    
    
  }
  .modalpics{
    height: 670px;
    width: 780px;
  }
  .closeBttn {
    padding: 1em;
    position: relative;
    left: 440px;
    top: 20px;
    font-size: 2rem;
    color: white;
    
    
  }
  .leftBttn {
    position: relative;
    left: 190px;
    font-size: 2rem;
  }
  .rightBttn {
    position: relative;
    right: 190px;
    font-size: 2rem;
  }
  /* .modal {
    width: 50%;
  } */
}




/* @media screen and (min-width: 750px) {
  .modalpics img{
    height: 500px;
    width: 650px;
  }

}  */

/* label{
  display: block;
  width: 25%;
  height: 30px;
  border: 1px solid var(--primary);
  border-radius: 0%;
  margin: 10px auto;
  line-height: 30px;
  color: var(--primary);
  font-weight: bold;
  font-size: 24px;
}
label:hover{
  background: var(--primary);
  color: white;
} */